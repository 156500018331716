<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="searchData" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="影院名称" name="cinemaName" class="ui-form__item">
            <a-input
              allowClear
              v-model:value="searchData.cinemaName"
              placeholder="请输入影院名称"
            ></a-input>
          </a-form-item>

          <a-form-item label="城市名称" name="cityName" class="ui-form__item">
            <a-input
              allowClear
              v-model:value="searchData.cityName"
              placeholder="请输入城市名称"
            ></a-input>
          </a-form-item>

          <a-form-item
            label="商圈名称"
            name="businessCircleName"
            class="ui-form__item"
          >
            <a-input
              allowClear
              v-model:value="searchData.businessCircleName"
              placeholder="请输入商圈名称"
            ></a-input>
          </a-form-item>

          <a-form-item
            label="站点名称"
            name="undergroundSiteName"
            class="ui-form__item"
          >
            <a-input
              allowClear
              v-model:value="searchData.undergroundSiteName"
              placeholder="请输入站点名称"
            ></a-input>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18"> </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
              >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px">
        <a-table
          :pagination="pagination"
          :columns="columns"
          :dataSource="list"
          rowKey="id"
          :scroll="{ x: 950 }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'businessCircleName'">
              {{ record.businessCircle ? record.businessCircle["name"] : "" }}
            </template>
            <template v-if="column.key === 'undergroundSiteName'">
              <span v-for="item in record.undergroundSiteList" :key="item.id"
                >{{ item.name }}<br
              /></span>
            </template>
            <template v-if="column.key === 'action'">
              <a-button v-permission="['cinema_basis_traffic_edit']" type="link" @click="onEdit(record)">编辑</a-button>
              <!-- <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>编辑</a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown> -->
            </template>
          </template>
        </a-table>
      </div>

      <a-modal
        title="编辑影院交通信息"
        style="width: 600px"
        v-model:visible="editModalVisible"
        @ok="onEditOk"
      >
        <a-spin :spinning="loading">
          <a-form
            style="margin-top: 30px"
            ref="modelForm"
            :model="modelRef"
            scrollToFirstError
            name="modelForm"
            :labelCol="{ span: 6, xxl: 5 }"
            :wrapperCol="{ span: 14, xxl: 13 }"
          >
            <a-form-item class="ui-form__item" name="citySortId" label="城市" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-select
                placeholder="请选择城市"
                allowClear
                v-model:value="modelRef.citySortId"
                style="width: 180px"
                @change="onGetCity"
              >
                <a-select-option
                  v-for="item in cityList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.city }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="areaSortId" label="区域" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-select
                placeholder="请选择区域"
                allowClear
                v-model:value="modelRef.areaSortId"
                style="width: 180px"
                @change="onGetArea"
              >
                <a-select-option
                  v-for="areaItem in areaList"
                  :key="areaItem.id"
                  :value="areaItem.id"
                  >{{ areaItem.area }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="businessCircleId" label="商圈" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-select
                placeholder="请选择商圈"
                allowClear
                v-model:value="modelRef.businessCircleId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="item in businessCircleList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="subway" label="地铁">
              <a-button @click="addSubway" type="primary">新增</a-button>
            </a-form-item>
            <div style="margin-top: 20px" v-if="undergroundSiteList.length">
              <a-table
                :pagination="false"
                :columns="subwayColumns"
                :dataSource="undergroundSiteList"
                rowKey="id"
                :scroll="{ x: 450 }"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'line'">
                    <a-select
                      placeholder="请选择线路"
                      v-model:value="record.undergroundId"
                      style="width: 150px"
                      @change="onGetSite"
                    >
                      <a-select-option
                        v-for="lineItem in lineList"
                        :key="lineItem.id"
                        :value="lineItem.id"
                        >{{ lineItem.name }}</a-select-option
                      >
                    </a-select>
                  </template>

                  <template v-if="column.key === 'site'">
                    <a-select
                      placeholder="请选择站点"
                      allowClear
                      :disabled="record.disabled"
                      v-model:value="record.id"
                      style="width: 150px"
                      @focus="onFocus(record)"
                    >
                      <a-select-option
                        v-for="siteItem in siteList"
                        :key="siteItem.id"
                        :value="siteItem.id"
                        >{{ siteItem.name }}</a-select-option
                      >
                    </a-select>
                  </template>

                  <template v-if="column.key === 'action'">
                    <a-button
                      type="primary"
                      @click="deleteSubway(record)"
                      :disabled="!record.id"
                      >删除</a-button
                    >
                  </template>
                </template>
              </a-table>
            </div>
          </a-form>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from "@/components/icon/icon.js";
import {
  getCinemaLocationList,
  getBaseCitySortList,
  getBaseAreaSortAllList,
  getBaseBusinessCircle,
  getBaseUndergroundGetAll,
  getBaseUndergroundSiteList,
  updateCinemaLocation,
} from "@/service/modules/cinema";
export default {
  components: {
    Icon,
  },
  data() {
    return {
      editModalVisible: false,
      loading: false,
      searchData: {},
      modelRef: {},
      cityList: [],
      areaList: [],
      businessCircleList: [],
      lineList: [],
      undergroundSiteList: [],
      siteList: [],
      columns: [
        {
          title: "影院",
          dataIndex: "cinemaName",
          width: 100,
        },
        {
          title: "城市",
          dataIndex: "city",
          width: 120,
        },
        {
          title: "商圈",
          key: "businessCircleName",
          width: 100,
        },
        {
          title: "地铁",
          key: "undergroundSiteName",
          width: 140,
        },
        {
          title: "操作",
          key: "action",
          width: 80,
        },
      ],
      list: [],
      subwayColumns: [
        {
          title: "线路",
          key: "line",
          width: 100,
        },
        {
          title: "站点",
          key: "site",
          width: 100,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 50,
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ["10", "20", "50", "100", "500"],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
    };
  },
  created() {
    this.getData();
    this.getCityList();
  },
  methods: {
    onFocus(item) {
      this.getBaseUndergroundSiteAllList(item.undergroundId);
    },
    addSubway() {
      this.siteList = [];
      let obj = {
        undergroundId: null,
        id: null,
        disabled: true,
      };
      this.undergroundSiteList.push(obj);
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.getData();
    },
    onEditOk() {
      this.$confirm({
        title: '提示',
        content: '确认要提交并保存吗？',
        onOk: async () => {
          let arr = [];
          this.modelRef.undergroundSiteIds = ''
          this.undergroundSiteList.forEach((item) => {
            arr.push(item.id);
            this.modelRef.undergroundSiteIds = arr.join(",");
          });
          // if (!this.modelRef.undergroundSiteIds) {
          //   this.$message.error("请配置地铁信息！");
          //   return;
          // }
          let obj = {
            id: this.modelRef.id,
            citySortId: this.modelRef.citySortId,
            businessCircleId: this.modelRef.businessCircleId,
            cinemaId: this.modelRef.cinemaId,
            undergroundSiteIds: this.modelRef.undergroundSiteIds,
          };
          this.updateCinemaBase(obj);
        }
      })
    },
    onEdit(item) {
      let flag = true;
      this.cityList.forEach(info => {
        if(info.id === item.citySortId) {
          flag = false;
        }
      })
      if(flag) {
        item.citySortId = null;
      }
      this.undergroundSiteList = []
      this.modelRef = {};
      let postData = JSON.parse(JSON.stringify(item));
      postData.citySortId = postData.citySortId
          ? postData.citySortId
          : null;
      postData.areaSortId = postData.businessCircle
        ? postData.businessCircle.areaSortId
        : null;
      postData.businessCircleId =
        postData.businessCircleId !== 0 ? postData.businessCircleId : null;
      this.modelRef = postData;
      if (postData.citySortId) {
        this.getBaseAreaSortAll(postData.citySortId);
        this.getBaseUndergroundAllList();
      }

      if (postData.businessCircleId) {
        this.getBaseBusinessCircleList(postData.areaSortId);
      }
      if (postData.undergroundSiteList.length > 0) {
        postData.undergroundSiteList.forEach((item) => {
          if (item.undergroundId) {
            item.disabled = false;
          }
          this.getBaseUndergroundSiteAllList(item.undergroundId);
        });

        this.undergroundSiteList = postData.undergroundSiteList;
      }
      this.editModalVisible = true;
    },
    onBack(isRef) {
      this.$emit("back", isRef);
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getCinemaLocationList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData,
        });
        this.loading = false;
        if (ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getCityList() {
      this.loading = true;
      try {
        const res = await getBaseCitySortList({
          page: 1,
          pageSize: 999,
        });
        this.loading = false;
        if (res.code === 200) {
          this.cityList = res.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getBaseAreaSortAll(citySortId) {
      this.loading = true;
      try {
        const res = await getBaseAreaSortAllList({ citySortId });
        this.loading = false;
        if (res.code === 200) {
          this.areaList = res.data;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getBaseBusinessCircleList(areaSortId) {
      this.loading = true;
      try {
        const res = await getBaseBusinessCircle({
          citySortId: this.modelRef.citySortId,
          areaSortId,
        });
        this.loading = false;
        if (res.code === 200) {
          this.businessCircleList = res.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getBaseUndergroundAllList() {
      this.loading = true;
      try {
        const res = await getBaseUndergroundGetAll({
          citySortId: this.modelRef.citySortId,
        });
        this.loading = false;
        if (res.code === 200) {
          this.lineList = res.data;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getBaseUndergroundSiteAllList(value) {
      this.siteList = [];
      this.loading = true;
      try {
        const res = await getBaseUndergroundSiteList({
          citySortId: this.modelRef.citySortId,
          undergroundId: value,
        });
        this.loading = false;
        if (res.code === 200) {
          this.siteList = res.data.list;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async updateCinemaBase(obj) {
      this.loading = true;
      try {
        const res = await updateCinemaLocation({
          ...obj,
        });
        this.loading = false;
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.editModalVisible = false;
          this.getData();
        }
      } catch (e) {
        this.loading = false;
      }
    },
    onGetCity(value) {
      this.getBaseAreaSortAll(value);
      this.getBaseUndergroundAllList(value);
      this.modelRef.areaSortId = null;
      this.businessCircleList = [];
      this.modelRef.businessCircleId = null;
      this.undergroundSiteList = [];
    },
    onGetArea(value) {
      this.getBaseBusinessCircleList(value);
      this.businessCircleList = [];
      this.modelRef.businessCircleId = null;
    },
    onGetSite(value) {
      this.undergroundSiteList.forEach((item) => {
        if (value === item.undergroundId) {
          item.disabled = false;
        }
      });
      // this.getBaseUndergroundSiteAllList(value);
    },
    deleteSubway(record) {
      this.undergroundSiteList.forEach((item, index) => {
        if (item.id === record.id) {
          this.undergroundSiteList.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
